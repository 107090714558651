import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import isMobile from 'is-mobile'

import './App.scss'

import Background from '../../components/Background'
import ConditionsView from '../Conditions'
import ContactView from '../Contact'
import GameView from '../Game'
import HomeView from '../Home'
import InfoView from '../Info'
import Navbar from '../../components/Navbar'
import ChooseMachine from '../ChooseMachine'

import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

const isMobileDevice = isMobile({ tablet: true, featureDetect: true })

const LanguageFr = () => {
  const { i18n } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage('fr')
  })

  return <Redirect to="/" />
}

const LanguageDe = () => {
  const { i18n } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage('de')
  })

  return <Redirect to="/" />
}

const App = () => {
  const { i18n } = useTranslation()

  if (window.location.search.indexOf('de') !== -1) {
    i18n.changeLanguage('de')
  } else if (window.location.search.indexOf('fr') !== -1) {
    i18n.changeLanguage('fr')
  }

  return (
    <>
      <div
        className={`base-layout ${isMobileDevice ? 'base-layout--mobile' : ''}`}
      >
        <BrowserRouter>
          <Navbar />

          <Switch>
            <Route path="/" component={HomeView} exact />

            <Route path="/conditions" component={ConditionsView} />

            <Route path="/contact" component={ContactView} />

            <Route path="/info" component={InfoView} />

            <Route path="/play" component={GameView} />

            <Route path="/choose" component={ChooseMachine} />

            <Route path="/fr" component={LanguageFr} />

            <Route path="/de" component={LanguageDe} />

            <Redirect from="*" to="/" />
          </Switch>
        </BrowserRouter>
      </div>

      <Background />
    </>
  )
}

export default App
