import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import coffeeMachine from '../../assets/images/coffee-machine.png'
import snow from '../../assets/images/snow.png'
import snowMobile from '../../assets/images/snow-mobile.png'

import './Home.scss'

const Home = () => {
  const { t } = useTranslation()

  return (
    <div className="container-page home-page">
      <h1>{t('HomePage.heading')}</h1>

      <h3>{t('HomePage.subHeading')}</h3>

      <p>{t('HomePage.text')}</p>

      <Link to="/play" className="base-button">
        {t('HomePage.link')}
      </Link>

      <img
        src={coffeeMachine}
        alt="Coffee machine"
        className="coffee-machine"
      />

      <img src={snow} alt="Snow" className="snow" />

      <img src={snowMobile} alt="Snow" className="snow-mobile" />
    </div>
  )
}

export default Home
