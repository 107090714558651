import Lottie from 'lottie-react'
import { useTranslation } from 'react-i18next'

import topViewImage1 from '../../assets/images/controller.png'

import './ChooseMachine.scss'

import animationData_desktop from './SetingAnimate_desktop'
import animationData_mobile from './SetingAnimate_mobile'
import snow from '../../assets/images/snow.png'

const ChooseMachine = ({ onStart }) => {
  const { t } = useTranslation()

  return (
    <div className="container-page choose-page">
      <div className="choose-page-title">{t('TutorialPage.heading')}</div>

      <div className="container-choose">
        <div className="step">
          <div className="contant-background stepTwo">
            <Lottie
              loop={true}
              autoplay={true}
              animationData={animationData_desktop(topViewImage1)}
              className="animate_desktop"
            />

            <Lottie
              loop={true}
              autoplay={true}
              animationData={animationData_mobile(topViewImage1)}
              className="animate_mobile"
            />
          </div>

          <div className="description">
            <div className="number">1.</div>

            <div className="text">{t('TutorialPage.step2')}</div>
          </div>
        </div>

        <div className="step stepThree">
          <div className="contant-background">
            <button className="base-button" onClick={() => onStart()}>
              {t('TutorialPage.start')}
            </button>
          </div>

          <div className="description">
            <div className="number">2.</div>

            <div className="text">{t('TutorialPage.step3')}</div>
          </div>
        </div>
      </div>

      <img src={snow} alt="Snow" className="snow" />
    </div>
  )
}

export default ChooseMachine
