import image_0 from './images/img_0.png'
import image_1 from './images/img_1.png'
import image_2 from '../../assets/images/item-1.png'

const data = (image) => {
  return {
    v: '5.7.4',
    fr: 30,
    ip: 0,
    op: 175,
    w: 2560,
    h: 1600,
    nm: '1.2_Desktiop_tutorial',
    ddd: 0,
    assets: [
      { id: 'image_0', w: 100, h: 110, u: '', p: image_0, e: 0 },
      { id: 'image_1', w: 300, h: 250, u: '', p: image_1, e: 0 },
      { id: 'image_2', w: 150, h: 180, u: '', p: image_2, e: 0 },
      { id: 'image_3', w: 260, h: 460, u: '', p: image, e: 0 },
    ],
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 2,
        nm: 'Pointer-228447 1.png',
        cl: 'png',
        refId: 'image_0',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 26,
                s: [100],
              },
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 32,
                s: [0],
              },
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 142,
                s: [0],
              },
              { t: 146, s: [100] },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 0,
                s: [1278, 1066, 0],
                to: [25.667, -32, 0],
                ti: [-3.667, 16, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 27,
                s: [1300, 970, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 146,
                s: [1300, 970, 0],
                to: [-3.667, 16, 0],
                ti: [-3.667, 16, 0],
              },
              { t: 168, s: [1278, 1066, 0] },
            ],
            ix: 2,
            l: 2,
          },
          a: { a: 0, k: [32.5, 38, 0], ix: 1, l: 2 },
          s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
        },
        ao: 0,
        ip: 0,
        op: 929,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 2,
        ty: 2,
        nm: 'grab.png',
        cl: 'png',
        refId: 'image_1',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 25,
                s: [0],
              },
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 31,
                s: [100],
              },
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 142,
                s: [100],
              },
              { t: 147, s: [0] },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 28,
                s: [1300, 972, 0],
                to: [40.667, 2.333, 0],
                ti: [37.901, -2.694, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 59,
                s: [1412, 950, 0],
                to: [-351.667, 25, 0],
                ti: [18.667, -3.667, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 106,
                s: [1074, 942, 0],
                to: [-18.667, 3.667, 0],
                ti: [-18.992, -1.09, 0],
              },
              { t: 142, s: [1300, 972, 0] },
            ],
            ix: 2,
            l: 2,
          },
          a: { a: 0, k: [112.5, 112.5, 0], ix: 1, l: 2 },
          s: { a: 0, k: [56.444, 56.444, 100], ix: 6, l: 2 },
        },
        ao: 0,
        ip: 0,
        op: 929,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 3,
        ty: 2,
        nm: 'kaffebohne_01 10.png',
        cl: 'png',
        refId: 'image_2',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 112,
                s: [0],
              },
              { t: 119, s: [100] },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [1268, 562, 0], ix: 2, l: 2 },
          a: { a: 0, k: [55.5, 55.5, 0], ix: 1, l: 2 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 109,
                s: [0, 0, 100],
              },
              { t: 118, s: [92, 92, 100] },
            ],
            ix: 6,
            l: 2,
          },
        },
        ao: 0,
        ip: 0,
        op: 929,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 4,
        ty: 2,
        nm: 'kaffebohne_01 10.png',
        cl: 'png',
        refId: 'image_2',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 55,
                s: [0],
              },
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 62,
                s: [100],
              },
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 103,
                s: [100],
              },
              { t: 109, s: [0] },
            ],
            ix: 11,
          },
          r: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 58,
                s: [0],
              },
              { t: 103, s: [141] },
            ],
            ix: 10,
          },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 58,
                s: [1268, 562, 0],
                to: [-28.333, 24.667, 0],
                ti: [17.333, -137.667, 0],
              },
              { t: 107, s: [1098, 710, 0] },
            ],
            ix: 2,
            l: 2,
          },
          a: { a: 0, k: [55.5, 55.5, 0], ix: 1, l: 2 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 58,
                s: [92, 92, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 94,
                s: [92, 92, 100],
              },
              { t: 107, s: [40, 40, 100] },
            ],
            ix: 6,
            l: 2,
          },
        },
        ao: 0,
        ip: 0,
        op: 929,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 5,
        ty: 2,
        nm: 'kaffebohne_01 10.png',
        cl: 'png',
        refId: 'image_2',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 49,
                s: [100],
              },
              { t: 55, s: [0] },
            ],
            ix: 11,
          },
          r: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 4,
                s: [0],
              },
              { t: 49, s: [141] },
            ],
            ix: 10,
          },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 4,
                s: [1268, 562, 0],
                to: [22.667, 23.667, 0],
                ti: [-120.667, -21.667, 0],
              },
              { t: 53, s: [1404, 704, 0] },
            ],
            ix: 2,
            l: 2,
          },
          a: { a: 0, k: [55.5, 55.5, 0], ix: 1, l: 2 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 4,
                s: [92, 92, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 40,
                s: [92, 92, 100],
              },
              { t: 53, s: [40, 40, 100] },
            ],
            ix: 6,
            l: 2,
          },
        },
        ao: 0,
        ip: 0,
        op: 929,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 6,
        ty: 2,
        nm: 'Kaffemaschine_einfach_01 2.png',
        cl: 'png',
        refId: 'image_3',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 29,
                s: [1256, 832, 0],
                to: [23, 0, 0],
                ti: [31, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 60,
                s: [1394, 832, 0],
                to: [-31, 0, 0],
                ti: [23, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 106,
                s: [1070, 832, 0],
                to: [-23, 0, 0],
                ti: [-31, 0, 0],
              },
              { t: 142, s: [1256, 832, 0] },
            ],
            ix: 2,
            l: 2,
          },
          a: { a: 0, k: [161, 215, 0], ix: 1, l: 2 },
          s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
        },
        ao: 0,
        ip: 0,
        op: 929,
        st: 0,
        bm: 0,
      },
    ],
    markers: [],
  }
}

export default data
