import './TypeProduct.scss'

const TypeProduct = ({ text, image, link }) => {
  return (
    <a className="type-product" href={link} target="_blank" rel="noreferrer">
      <img src={image} alt={text} />

      <span>{text}</span>
    </a>
  )
}

export default TypeProduct
