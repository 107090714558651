import { Trans, useTranslation } from 'react-i18next'

import './Conditions.scss'

const Conditions = () => {
  const { t } = useTranslation()

  return (
    <div className="container-page conditions-page">
      <h2>{t('ConditionsPage.heading')}</h2>

      <h4>{t('ConditionsPage.subHeading')}</h4>

      <p>
        <Trans i18nKey="ConditionsPage.text">
          <a href="https://www.tchibo.ch/datenschutz" target="_blank" />
        </Trans>
      </p>
    </div>
  )
}

export default Conditions
