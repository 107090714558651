import Particles from 'react-particles-js'

import Snowflakes1 from '../../assets/images/snowflakes_01.svg'
import Snowflakes2 from '../../assets/images/snowflakes_02.svg'
import Snowflakes3 from '../../assets/images/snowflakes_03.svg'
import Snowflakes4 from '../../assets/images/snowflakes_04.svg'

import './Background.scss'

const Background = () => {
  const customization = {
    particles: {
      number: {
        value: 5,
        density: {
          enable: true,
          value_area: 800,
        },
      },
      color: {
        value: '#ffffff',
      },
      shape: {
        type: 'images',
        image: [
          {
            src: Snowflakes1,
            width: 332,
            height: 371,
          },
          {
            src: Snowflakes2,
            width: 179,
            height: 171,
          },
          {
            src: Snowflakes3,
            width: 206,
            height: 194,
          },
          {
            src: Snowflakes4,
            width: 328,
            height: 368,
          },
        ],
      },
      opacity: {
        value: 0.75,
        random: false,
        anim: {
          enable: false,
          speed: 1,
          opacity_min: 0.1,
          sync: false,
        },
      },
      size: {
        value: 100,
        random: true,
        anim: {
          enable: false,
          speed: 40,
          size_min: 0.1,
          sync: false,
        },
      },
      line_linked: {
        enable: false,
        distance: 150,
        color: '#ffffff',
        opacity: 0.4,
        width: 1,
      },
      move: {
        enable: true,
        speed: 5,
        direction: 'none',
        random: false,
        straight: false,
        out_mode: 'out',
        bounce: false,
        attract: {
          enable: false,
          rotateX: 600,
          rotateY: 1200,
        },
      },
    },
  }

  return <Particles params={customization} />
}

export default Background
