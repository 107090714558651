import React from 'react'
import ReactDOM from 'react-dom'

import './i18n'
import './styles/index.scss'

import App from './views/App'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)
