import React from 'react'
import { Link } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { Formik, Form, useField } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'

import './Form.scss'

const TextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props)

  return (
    <div className="formItem textInput">
      <label htmlFor={props.id || props.name}>{label}</label>

      <input
        className={`text-input ${
          meta.touched && meta.error ? 'error_form' : ''
        }`}
        {...field}
        {...props}
      />
    </div>
  )
}

const Checkbox = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' })

  return (
    <div
      className={`formItem checkbox ${
        meta.touched && meta.error ? 'error_form' : ''
      }`}
    >
      <input className="checkbox-input" type="checkbox" {...field} {...props} />

      <label>{children}</label>
    </div>
  )
}

const SignupForm = ({ score }) => {
  const { t, i18n } = useTranslation()

  const handleSubmit = async (values) => {
    const data = {
      ...values,
      points: score,
      lang: i18n.language,
    }
    const headers = {
      'Content-Type': 'application/json',
    }

    await axios.post(process.env.REACT_APP_API_URL, data, { headers })

    i18n.language === 'de'
      ? document.location.replace(
          'https://tagm.tchibo.ch/cl.aspx?tc=8ee2f9b5946ee352da684e5c78e144b0&url=https://www.tchibo.ch/adventsgame-c402016000.html/?utm_source=gotomo&utm_medium=micropage&utm_campaign=adventsgame_2021',
        )
      : document.location.replace(
          'https://tagm.tchibo.ch/cl.aspx?tc=8ee2f9b5946ee352da684e5c78e144b0&url=https://www.fr.tchibo.ch/adventgame-c402016001.html/?utm_source=gotomo&utm_medium=micropage&utm_campaign=adventsgame_2021',
        )
  }

  return (
    <div className="containerForm">
      <h2>{t('Form.heading')}</h2>

      <h4>{t('Form.description')}</h4>

      <h4>{t('Form.subHeading')}</h4>

      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          conditions: false,
          updates: false,
        }}
        validationSchema={Yup.object({
          firstName: Yup.string().max(40).required(),
          lastName: Yup.string().max(40).required(),
          email: Yup.string().max(50).email().required(),
          conditions: Yup.boolean().oneOf([true]),
          updates: Yup.boolean().oneOf([true]),
        })}
        onSubmit={handleSubmit}
      >
        <Form className="Form">
          <TextInput
            label={t('Form.labelSecondName')}
            name="lastName"
            type="text"
          />

          <TextInput
            label={t('Form.labelFirstName')}
            name="firstName"
            type="text"
          />

          <TextInput label={t('Form.labelEmail')} name="email" type="email" />

          <Checkbox name="conditions">
            {t('Form.labelConditions1')}

            <Link to="/conditions" target="_blank">
              {t('Form.labelConditionsLink')}
            </Link>

            {t('Form.labelConditions2')}
          </Checkbox>

          <Checkbox name="updates">
            <Trans i18nKey="Form.labelNewsletter">
              <a
                href="https://www.tchibo.ch/subscribenewsletter?query=newsletter"
                target="_blank"
              />
              <Link to="/conditions" target="_blank" rel="noreferrer" />
              <a
                href="https://www.tchibo.ch/datenschutz-und-sicherheit-bei-tchibo-s400028507.html"
                target="_blank"
              />
            </Trans>
          </Checkbox>

          <button className="base-button" type="submit">
            {t('Form.submit')}
          </button>
        </Form>
      </Formik>
    </div>
  )
}

export default SignupForm
