import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Countdown from '../Countdown'
import GameInfo from '../GameInfo'
import PieTimer from '../PieTimer'

import './GameOverlay.scss'

const GameOverlay = ({
  children,
  isFinished,
  isStarted,
  minScore,
  score,
  time,
  onFinish,
  onRestart,
  onStart,
}) => {
  const { t } = useTranslation()
  const [isIntermediate, setIntermediate] = useState(window.innerWidth <= 992)
  const [isStartedInternal, setStartedInternal] = useState(false)

  const handleStart = () => {
    setStartedInternal(true)
    setTimeout(() => onStart(), 3000)
  }

  if (!isStarted) {
    return (
      <div className="game-overlay game-overlay--started">
        {isStartedInternal ? (
          <div>
            <PieTimer />

            <Countdown primary time={3} formatted={false} />
          </div>
        ) : (
          <>
            <div>
              <PieTimer playing={false} />

              <div className="primary">3</div>
            </div>

            <button className="base-button" onClick={handleStart}>
              {t('Game.start')}
            </button>
          </>
        )}
      </div>
    )
  }

  if (!isFinished) {
    return (
      <div className="game-overlay">
        <GameInfo score={score} time={time} onFinish={() => onFinish()} />
      </div>
    )
  }

  const handleIntermediateSkip = () => {
    setIntermediate(false)
  }

  const handleRestart = () => {
    setIntermediate(window.innerWidth <= 992)
    onRestart()
  }

  return (
    <div className="game-overlay game-overlay--finished">
      {isIntermediate || score < minScore ? (
        <>
          <h3>{t(score < minScore ? 'Game.lost' : 'Game.won')}</h3>

          <GameInfo score={score} />

          {isIntermediate ? (
            <div className="game-overlay__actions">
              <button className="base-button" onClick={handleIntermediateSkip}>
                {t('Game.continue')}
              </button>
            </div>
          ) : (
            <>
              <p>{t('Game.failed')}</p>

              <div className="game-overlay__actions">
                <button className="base-button" onClick={handleRestart}>
                  {t('Game.restart')}
                </button>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="game-overlay__success">
          <div>
            <h3>{t('Game.won')}</h3>

            <GameInfo score={score} />
          </div>

          <div>{children}</div>
        </div>
      )}
    </div>
  )
}

export default GameOverlay
