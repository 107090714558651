import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import './Navbar.scss'

import Icon from '../Icon'

const languages = ['de', 'fr']

const Navbar = () => {
  const [isMenuVisible, setMenuVisible] = useState(false)
  const { t, i18n } = useTranslation()

  const isHomeLinkActive = (match, location) => {
    return /^\/(play)?$/.test(location.pathname)
  }

  const closeMenu = () => {
    setMenuVisible(false)
  }

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible)
  }

  const switchLanguage = (language) => {
    i18n.changeLanguage(language)
  }

  return (
    <nav>
      <NavLink to="/" isActive={isHomeLinkActive}>
        <span>{t('Navigation.homepage')}</span>
      </NavLink>

      <NavLink to="/info">
        <span>{t('Navigation.info')}</span>
      </NavLink>

      <NavLink to="/conditions">
        <span>{t('Navigation.conditions')}</span>
      </NavLink>

      <NavLink to="/contact">
        <span>{t('Navigation.contact')}</span>
      </NavLink>

      <div className="mobile-control" onClick={toggleMenu}>
        <Icon name={isMenuVisible ? 'close' : 'menu'} />
      </div>

      <div className="logo">
        {languages.map((language) => (
          <span
            key={language}
            className={`language-switcher-link ${
              language === i18n.language ? 'language-switcher-link--active' : ''
            }`}
            onClick={() => switchLanguage(language)}
          >
            <span>{language}</span>
          </span>
        ))}

        <Icon name="logo" width="140" height="40" />
      </div>

      {isMenuVisible && (
        <div className="mobile-menu" onClick={closeMenu}>
          <NavLink to="/" isActive={isHomeLinkActive}>
            <span>{t('Navigation.homepage')}</span>
          </NavLink>

          <NavLink to="/info">
            <span>{t('Navigation.info')}</span>
          </NavLink>

          <NavLink to="/conditions">
            <span>{t('Navigation.conditions')}</span>
          </NavLink>

          <NavLink to="/contact">
            <span>{t('Navigation.contact')}</span>
          </NavLink>

          {languages.map((language) => (
            <span
              key={`${language}-mobile`}
              className={`language-switcher-link ${
                language === i18n.language
                  ? 'language-switcher-link--active'
                  : ''
              }`}
              onClick={() => switchLanguage(language)}
            >
              <span>{language}</span>
            </span>
          ))}

          <div className="mobile-menu__backdrop"></div>
        </div>
      )}
    </nav>
  )
}

export default Navbar
