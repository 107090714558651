import {
  forwardRef,
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
} from 'react'
import shuffle from 'lodash.shuffle'

import './GameItems.scss'

const GameItems = (
  {
    numOfItems = 30,
    picturesBonus = [],
    picturesNegative = [],
    picturesPositive = [],
    spawnRateBonus = 0.05,
    spawnRateNegative = 0.1,
    itemInterval = 750,
    itemDuration = 5000,
    onActiveAreaChange,
  },
  ref,
) => {
  const containerRef = useRef()
  const [items, setItems] = useState([])

  useImperativeHandle(ref, () => ({
    hide(item) {
      setItems((prevItems) => {
        const newItems = [...prevItems]
        const updatedItem = newItems.find(({ key }) => key === item.key)
        updatedItem.hidden = true
        return newItems
      })
    },
  }))

  useEffect(() => {
    const randomItems = getRandomItems()
    setItems(randomItems)
    const itemStartInterval = launchItems()
    const activeAreaChangeInterval = observeActiveArea(randomItems)

    return () => {
      clearInterval(itemStartInterval)
      clearInterval(activeAreaChangeInterval)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getRandomItems = () => {
    const numOfBonus = Math.round(numOfItems * spawnRateBonus) || 1
    const numOfNegative = Math.round(numOfItems * spawnRateNegative) || 1

    let newItems = Array(numOfItems)
      .fill({ points: 1, started: false })
      .map((item, i) => ({
        ...item,
        key: `item-${i}`,
        x: Math.round(Math.random() * 80 + 10),
        picture: getRandomPicture(),
      }))

    newItems.slice(0, numOfBonus).forEach((item) => {
      item.picture = getRandomPicture(picturesBonus)
      item.points = 5
    })

    newItems.slice(numOfBonus, numOfBonus + numOfNegative).forEach((item) => {
      item.picture = getRandomPicture(picturesNegative)
      item.points = -2
    })

    newItems = shuffle(newItems)

    const durationDecreaseStep = (itemDuration * 0.7) / numOfItems
    newItems.forEach((item, i) => {
      item.duration = itemDuration - durationDecreaseStep * i
    })

    return newItems
  }

  const getRandomPicture = (pictures = picturesPositive) => {
    return pictures[Math.round(Math.random() * (pictures.length - 1))]
  }

  const launchItems = () => {
    let i = 0

    const interval = setInterval(() => {
      if (i >= numOfItems) {
        clearInterval(interval)
        return
      }

      setItems((prevItems) => {
        const newItems = [...prevItems]
        newItems[i].started = true
        return newItems
      })

      i++
    }, itemInterval)

    return interval
  }

  const observeActiveArea = (initialItems) => {
    let activeNodes = []

    const activeAreaChangeInterval = setInterval(() => {
      if (!containerRef.current) {
        return
      }

      const containerHeight = containerRef.current.clientHeight
      const activeAreaTop = containerHeight - containerHeight * 0.3
      const activeAreaBottom = containerHeight - containerHeight * 0.1
      const nodes = [...containerRef.current.children]
      const newActiveNodes = nodes.filter(
        (node) =>
          node.offsetTop >= activeAreaTop && node.offsetTop <= activeAreaBottom,
      )

      if (
        newActiveNodes.length !== activeNodes.length ||
        newActiveNodes.some((node, i) => node !== activeNodes[i])
      ) {
        activeNodes = newActiveNodes
        onActiveAreaChange(
          activeNodes.map((node) => initialItems[nodes.indexOf(node)]),
        )
      }
    }, 100)

    return activeAreaChangeInterval
  }

  return (
    <div ref={containerRef} className="game-items">
      {items.map((item) => (
        <div
          key={item.key}
          className="game-items__item"
          style={{
            display: item.hidden ? 'none' : 'block',
            top: `${item.started ? 110 : -10}%`,
            left: `${item.x}%`,
            transitionDuration: `${item.duration}ms`,
          }}
        >
          <div>
            <img alt="" draggable="false" src={item.picture} />

            {item.points !== 1 && (
              <span
                className={`game-items__label ${
                  item.points > 1 ? 'game-items__label--bonus' : ''
                }`}
              >
                {item.points > 0 && '+'}
                {item.points}
              </span>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export default forwardRef(GameItems)
