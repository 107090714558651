import image_0 from '../../assets/images/item-1.png'

const data = (image) => {
  return {
    v: '5.7.4',
    fr: 30,
    ip: 0,
    op: 182,
    w: 414,
    h: 1000,
    nm: '1.2_Mobile_Tutorial',
    ddd: 0,
    assets: [
      { id: 'image_0', w: 60, h: 80, u: '', p: image_0, e: 0 },
      { id: 'image_1', w: 187, h: 330, u: '', p: image, e: 0 },
    ],
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 4,
        nm: 'Formebene 2',
        sr: 1,
        ks: {
          o: { a: 0, k: 70, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 0,
                s: [267, 757, 0],
                to: [-9, 0.667, 0],
                ti: [28, 13.333, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 33,
                s: [213, 761, 0],
                to: [0.227, 0.12, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 54,
                s: [213, 761, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 81,
                s: [273, 757, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 117,
                s: [147, 757, 0],
                to: [0, 0, 0],
                ti: [-20, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.167, y: 0.167 },
                t: 146,
                s: [210, 757, 0],
                to: [20, 0, 0],
                ti: [-9.5, 0, 0],
              },
              { t: 166, s: [267, 757, 0] },
            ],
            ix: 2,
            l: 2,
          },
          a: { a: 0, k: [84, 314, 0], ix: 1, l: 2 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 34,
                s: [100, 100, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 41,
                s: [75, 75, 100],
              },
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 166,
                s: [75, 75, 100],
              },
              { t: 174, s: [100, 100, 100] },
            ],
            ix: 6,
            l: 2,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [32.957, 32.957], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Elliptischer Pfad 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'st',
                c: {
                  a: 0,
                  k: [0.329241105622, 0.325214939491, 0.325214939491, 1],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 1.5, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Kontur 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.761265833238, 0.749685070561, 0.750604188208, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 3,
                nm: 'Fläche 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [84.478, 309.478], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transformieren',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 929,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 2,
        ty: 2,
        nm: 'kaffebohne_01 10.png',
        cl: 'png',
        refId: 'image_0',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 127,
                s: [0],
              },
              { t: 131, s: [100] },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [214, 624.5, 0], ix: 2, l: 2 },
          a: { a: 0, k: [18, 18.5, 0], ix: 1, l: 2 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 128,
                s: [47, 47, 100],
              },
              { t: 134, s: [90, 90, 100] },
            ],
            ix: 6,
            l: 2,
          },
        },
        ao: 0,
        ip: 0,
        op: 929,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 3,
        ty: 2,
        nm: 'kaffebohne_01 10.png',
        cl: 'png',
        refId: 'image_0',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 79,
                s: [0],
              },
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 83,
                s: [100],
              },
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 113,
                s: [100],
              },
              { t: 120, s: [0] },
            ],
            ix: 11,
          },
          r: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 81,
                s: [0],
              },
              { t: 117, s: [117] },
            ],
            ix: 10,
          },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 81,
                s: [214, 624.5, 0],
                to: [-10.5, 8.417, 0],
                ti: [12.917, -28, 0],
              },
              { t: 117, s: [151, 675, 0] },
            ],
            ix: 2,
            l: 2,
          },
          a: { a: 0, k: [18, 18.5, 0], ix: 1, l: 2 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 110,
                s: [90, 90, 100],
              },
              { t: 119, s: [47, 47, 100] },
            ],
            ix: 6,
            l: 2,
          },
        },
        ao: 0,
        ip: 0,
        op: 929,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 4,
        ty: 2,
        nm: 'kaffebohne_01 10.png',
        cl: 'png',
        refId: 'image_0',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 77,
                s: [100],
              },
              { t: 84, s: [0] },
            ],
            ix: 11,
          },
          r: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 45,
                s: [0],
              },
              { t: 81, s: [117] },
            ],
            ix: 10,
          },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 45,
                s: [214, 624.5, 0],
                to: [7.5, 8.417, 0],
                ti: [12.917, -28, 0],
              },
              { t: 81, s: [259, 675, 0] },
            ],
            ix: 2,
            l: 2,
          },
          a: { a: 0, k: [18, 18.5, 0], ix: 1, l: 2 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 74,
                s: [90, 90, 100],
              },
              { t: 83, s: [47, 47, 100] },
            ],
            ix: 6,
            l: 2,
          },
        },
        ao: 0,
        ip: 0,
        op: 929,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 5,
        ty: 2,
        nm: 'Group 12.png',
        cl: 'png',
        refId: 'image_1',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.504, y: 1 },
                o: { x: 0.404, y: 0 },
                t: 54,
                s: [206, 714, 0],
                to: [11.25, 0, 0],
                ti: [9.583, 0, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                t: 81,
                s: [273.5, 714, 0],
                to: [-9.583, 0, 0],
                ti: [11.25, 0, 0],
              },
              {
                i: { x: 0.667, y: 1 },
                o: { x: 0.333, y: 0 },
                t: 117,
                s: [148.5, 714, 0],
                to: [-11.25, 0, 0],
                ti: [-9.583, 0, 0],
              },
              { t: 146, s: [206, 714, 0] },
            ],
            ix: 2,
            l: 2,
          },
          a: { a: 0, k: [93.5, 70, 0], ix: 1, l: 2 },
          s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
        },
        ao: 0,
        ip: 0,
        op: 929,
        st: 0,
        bm: 0,
      },
    ],
    markers: [],
  }
}

export default data
