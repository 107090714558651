import { useTranslation } from 'react-i18next'

import TypeProduct from '../../components/TypeProduct'

import image1 from '../../assets/images/Kaffeezubehör.png'
import image2 from '../../assets/images/Kaffeemaschinen-CO.png'
import image3 from '../../assets/images/Adventspreise.png'
import image4 from '../../assets/images/Kaffeewissen.png'

import './Info.scss'

const Info = () => {
  const { t, i18n } = useTranslation()

  const cardsData = [
    {
      text: t('InfoPage.link1Label'),
      link: t('InfoPage.link1'),
      image: image1,
    },
    {
      text: t('InfoPage.link2Label'),
      link: t('InfoPage.link2'),
      image: image2,
    },
    {
      text: t('InfoPage.link3Label'),
      link: t('InfoPage.link3'),
      image: image3,
    },
  ]

  if (i18n.language === 'de') {
    cardsData.push({
      text: t('InfoPage.link4Label'),
      link: t('InfoPage.link4'),
      image: image4,
    })
  }

  return (
    <div className="container-page info-page">
      <h2>
        {t('InfoPage.heading')} <br />
        {t('InfoPage.subHeading')}
      </h2>

      <div className="info-page__cards">
        {cardsData.map((item, i) => (
          <TypeProduct
            key={i}
            text={item.text}
            link={item.link}
            image={item.image}
          />
        ))}
      </div>
    </div>
  )
}

export default Info
