import { useTranslation } from 'react-i18next'

import Icon from '../Icon'

import './GameOrientationWarning.scss'

const GameOrientationWarning = () => {
  const { t } = useTranslation()

  return (
    <div className="game-orientation-warning">
      <Icon name="rotation" width="135" height="115" />

      <div>{t('Game.orientationWarning')}</div>
    </div>
  )
}

export default GameOrientationWarning
