import { useState, useEffect } from 'react'

import './Countdown.scss'

const Countdown = ({
  time = 0,
  formatted = true,
  primary = false,
  onFinish,
}) => {
  const [timeLeft, setTimeLeft] = useState(0)

  useEffect(() => {
    setTimeLeft(time)

    if (!time) {
      return
    }

    const interval = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        if (prevTimeLeft < 1) {
          clearInterval(interval)
          setTimeout(onFinish)
          return 0
        }

        return prevTimeLeft - 1
      })
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [time]) // eslint-disable-line react-hooks/exhaustive-deps

  const getTimeLeftFomatted = (time) => {
    return [Math.floor(time / 3600), Math.floor(time / 60), time % 60]
      .map((item) => (item < 10 ? `0${item}` : item))
      .join(':')
  }

  return (
    <div className={primary ? 'primary' : ''}>
      {formatted ? getTimeLeftFomatted(timeLeft) : timeLeft}
    </div>
  )
}

export default Countdown
