import { useTranslation } from 'react-i18next'

import Countdown from '../Countdown'

import './GameInfo.scss'

const GameInfo = ({ time = 0, score = 0, onFinish }) => {
  const { t } = useTranslation()

  return (
    <>
      <Countdown time={time} onFinish={() => onFinish()} />

      <div>{t('Game.score', { count: score })}</div>
    </>
  )
}

export default GameInfo
