import './PieTimer.scss'

const PieTimer = ({ playing = true }) => {
  return (
    <div className="pie-timer">
      {playing ? (
        <>
          <div className="pie-timer__shadow"></div>

          <div className="pie-timer__right"></div>

          <div className="pie-timer__frame">
            <div className="pie-timer__left"></div>
          </div>
        </>
      ) : (
        <div className="pie-timer__placeholder"></div>
      )}
    </div>
  )
}

export default PieTimer
