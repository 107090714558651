import './Icon.scss'

import icons from './data'

const Icon = ({ name, width = 40, height = 40 }) => {
  if (!icons[name]) {
    return null
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={icons[name].viewBox}
      width={width}
      height={height}
    >
      <g>{icons[name].template}</g>
    </svg>
  )
}

export default Icon
