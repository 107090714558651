import { useState } from 'react'
import { Howl } from 'howler'

import Form from '../../components/Form'
import GameContainer from '../../components/GameContainer'
import TutorialView from '../ChooseMachine'

import controller from '../../assets/images/controller.png'
import item1 from '../../assets/images/item-1.png'
import itemBonus1 from '../../assets/images/item-2.png'
import itemNegative1 from '../../assets/images/item-negative-1.png'
import soundGameLost from '../../assets/sounds/game-over-lost.wav'
import soundGameWon from '../../assets/sounds/game-over-won.wav'
import soundItemBonus from '../../assets/sounds/item-bonus.wav'
import soundItemNegative from '../../assets/sounds/item-negative.wav'
import soundItemPositive from '../../assets/sounds/item-positive.wav'
import snow from '../../assets/images/snow.png'
import snowMobile from '../../assets/images/snow-mobile.png'

import './Game.scss'

const pictures = {
  bonus: [itemBonus1],
  controller,
  negative: [itemNegative1],
  positive: [item1],
}
const sounds = {
  bonus: new Howl({ src: [soundItemBonus], html5: true }),
  lost: new Howl({ src: [soundGameLost], html5: true }),
  negative: new Howl({ src: [soundItemNegative], html5: true }),
  positive: new Howl({ src: [soundItemPositive], html5: true }),
  won: new Howl({ src: [soundGameWon], html5: true }),
}

const minScoreToWin = +process.env.REACT_APP_MIN_SCORE_TO_WIN
const numOfItems = +process.env.REACT_APP_NUM_OF_ITEMS
const time = +process.env.REACT_APP_GAME_ROUND_TIME

const Game = () => {
  const [isTutorialSkiped, setTutorialSkiped] = useState(false)

  const handleTutorialSkip = () => {
    setTutorialSkiped(true)
  }

  return (
    <div className="game-view">
      {isTutorialSkiped ? (
        <>
          <GameContainer
            Form={Form}
            minScore={minScoreToWin}
            numOfItems={numOfItems}
            pictureController={pictures.controller}
            picturesBonus={pictures.bonus}
            picturesNegative={pictures.negative}
            picturesPositive={pictures.positive}
            soundBonus={sounds.bonus}
            soundLose={sounds.lost}
            soundNegative={sounds.negative}
            soundPositive={sounds.positive}
            soundWin={sounds.won}
            time={time}
          />

          <img src={snow} alt="Snow" className="snow" />

          <img src={snowMobile} alt="Snow" className="snow-mobile" />
        </>
      ) : (
        <TutorialView onStart={handleTutorialSkip} />
      )}
    </div>
  )
}

export default Game
