import { useTranslation } from 'react-i18next'

import './Contact.scss'

const Contact = () => {
  const { t } = useTranslation()

  return (
    <div className="container-page contact-page">
      <h2>{t('ContactPage.heading')}</h2>

      <h4>{t('ContactPage.subHeading')}</h4>

      <p>
        {t('ContactPage.companyName')} <br />
        {t('ContactPage.address1')} <br />
        {t('ContactPage.address2')}
      </p>

      <p>
        {t('ContactPage.phoneNumberLabel')} <br />
        {t('ContactPage.phoneNumber')}
      </p>
    </div>
  )
}

export default Contact
