import { useRef, useState } from 'react'

import './GameController.scss'

const GameController = ({ picture, onPositionChange }) => {
  const tickRef = useRef()
  const [active, setActive] = useState(false)
  const [currentX, setCurrentX] = useState()
  const [initialX, setInitialX] = useState()

  const handleDragStart = (e) => {
    if (e.target !== tickRef.current) {
      return
    }

    e.preventDefault()

    if (e.type === 'mousedown') {
      setInitialX(e.clientX - tickRef.current.offsetLeft)
    } else if (e.type === 'touchstart') {
      setInitialX(e.touches[0].clientX - tickRef.current.offsetLeft)
    }

    setActive(true)
  }

  const handleDragMove = (e) => {
    let x

    if (e.type === 'mousemove') {
      x = e.clientX - initialX
    } else if (e.type === 'touchmove') {
      x = e.touches[0].clientX - initialX
    }

    const containerWidth = tickRef.current.parentElement.clientWidth
    const tickWidth = tickRef.current.clientWidth
    const limitLeft = tickWidth / 2
    const limitRight = containerWidth - limitLeft

    if (x < limitLeft) {
      x = limitLeft
    } else if (x > limitRight) {
      x = limitRight
    }

    const relativeX = (x / containerWidth) * 100
    const offsetX = ((tickWidth / containerWidth) * 100) / 2.5
    tickRef.current.style.left = `${relativeX}%`
    onPositionChange([relativeX - offsetX, relativeX + offsetX])
    setCurrentX(x)
  }

  const handleDragEnd = () => {
    setInitialX(currentX)
    setActive(false)
  }

  return (
    <div
      className="game-controller"
      onMouseDown={handleDragStart}
      onMouseMove={active ? handleDragMove : undefined}
      onMouseUp={handleDragEnd}
      onTouchStart={handleDragStart}
      onTouchMove={active ? handleDragMove : undefined}
      onTouchEnd={handleDragEnd}
    >
      <img
        ref={tickRef}
        alt=""
        className="game-controller__tick"
        draggable="false"
        src={picture}
      />
    </div>
  )
}

export default GameController
